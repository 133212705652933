import React from 'react';

// Assets
import Facebook from '../Icons/facebook';
import Instagram from '../Icons/instagram';
import Twitter from '../Icons/twitter';
import Github from '../Icons/github';
import LinkedIn from '../Icons/linkedin';

const FooterLink = ({link, icon, alt}) => (
  <a href={link} target="_new" className="footer__link">
    {icon}
  </a>
);

const Footer = () => (
  <footer className="footer">
    <div className="footer__links">
      <FooterLink link="https://github.com/gcauchon" icon={<Github />} alt="Github" /> 
      <FooterLink link="http://instagram.com/gcauchon" icon={<Instagram />} alt="Instagram" /> 
      <FooterLink link="https://www.facebook.com/gcauchon" icon={<Facebook />} alt="Facebook" /> 
      <FooterLink link="https://twitter.com/gcauchon" icon={<Twitter />} alt="Twitter" /> 
      <FooterLink link="http://ca.linkedin.com/in/gcauchon" icon={<LinkedIn />} alt="LinkedIn" /> 
    </div>

    guillaume@cauchon.me © 2020
  </footer> 
);

export default Footer;
