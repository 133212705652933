import React from 'react';
import Layout from "../components/layout"

const Link = ({url, text}) => (
  <a href={url} target="_new" className={`code code--${text}`}>{text}</a>
)

const IndexPage = () => (
  <Layout>

    <h2 class="title"><span role="img" aria-label="family">👨‍👩‍👦‍👦</span></h2>

    <p>
      Amoureux d’une femme extraordinaire et papa comblé de deux garçons. 
    </p>

    <h2 class="title"><span role="img" aria-label="work">💻</span></h2>

    <p>
      Développeur chez <a href="http://mirego.com" target="_new" className="mirego">MIREGO</a>, 
      foundateur d’<a href="https://www.alagarderie.ca" target="_new" className="alagarderie">À la garderie.ca</a>&nbsp;
      et <em>wannabe</em> entrepreneur numérique. Passionné de technologie lorsqu’elle permet de changer les habitudes, de 
      briser le statu-quo, de transformer des industries et d’améliorer notre 
      vie au quotidien.
    </p>

    <p> 
      <Link url="https://elixir-lang.org" text="elixir" />,&nbsp; 
      <Link url="http://phoenixframework.org" text="phoenix" />,&nbsp;
      <Link url="https://kubernetes.io" text="kubernetes" />,&nbsp; 
      <Link url="https://aws.amazon.com" text="aws" />,&nbsp;
      <Link url="https://cloud.google.com" text="gcp" /> et&nbsp;
      <Link url="https://www.terraform.io" text="terraform" /> sont les technologies
      qui me captivent et avec lesquelles j’occupe la grande partie de mon temps. 
      Je m’intéresse également à l’expérience utilisateur, aux objets connectés 
      et à l’application des concepts d’intelligence artificielle dans les produits 
      numériques.
    </p>

    <h2 class="title"><span role="img" aria-label="outdoors">⛰</span></h2>

    <p>
      Le plein air, le camping, la chasse, la mécanique et la menuiserie occupent 
      mes temps libres. Le travail manuel est important pour garder un bon équilibre 
      avec les nombreueses heures passées devant un écran.
    </p> 
  </Layout>
);

export default IndexPage;
