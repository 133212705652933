import React from 'react';
import {Helmet} from 'react-helmet';

// Components
import Header from './Header';
import Footer from './Footer';

// Stylesheet
import './style.css';

const Layout = ({ children }) => (
  <div>
    <Helmet
      title="Guillaume Cauchon → @gcauchon"
      meta={[
        { name: 'description', content: 'What can code do for you?' },
        { name: 'keywords', content: 'elixir, phoenix, erlang, ruby, rubyonrails, rails, java, j2ee, javaEE, node.js, node, mobile, ux, user experience, design' },
      ]}
    />

    <Header />

    <div className="container">
      {children}
    </div>

    <Footer />
  </div>
);

export default Layout;
