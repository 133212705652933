import React from 'react';

// Assets
import avatar from '../../assets/me.jpg';

const Header = () => (
  <header className="header">
    <img src={avatar} alt="it’s me!" className="header__avatar"/>
  </header>
)

export default Header
